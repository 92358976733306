
p {

}

h1, h2, h3, h4, h5, h6 {

}

a {
    color: #fff;
    text-decoration: none;
    transition: color .2s ease-in-out;

    &:hover {
        color: darken(@color-primary, 20%);
    }
}
