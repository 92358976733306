.Search {
    display: inline-block;

    position: relative;

    &-control {
        display: flex;
    }

    &-input {
        width: 20vw;
        font-size: 2em;
        background: rgba(255,255,255,0.1);
        border: 1px solid transparent;
        border-bottom: 1px solid rgba(255,255,255,0.6);
        padding: 0.5em 1em;
        transition: all ease-in-out .2s;
        color: #fff;
        display: block;
        text-shadow: rgba(0,0,0,0.4) 2px 2px 3px;
        &:focus {
            width: 60vw;
            outline: none;
            background: rgba(0,0,0,0.1);
        }
        &:hover {
            border: 1px solid rgba(255,255,255,0.6);
        }
    }

    &-random {
        display: flex;
        padding: 1em;
        background: rgba(0,0,0,0.2);
        text-align: center;
        align-items: center;
    }



    &-filter {
        position: absolute;
        width: 100%;
    }

    &-link {
        color: rgba(255,255,255,0.6);
        display: block;
        background: rgba(0,0,0,0.8);
        padding: 0.5em 1em;

        &:focus,
        &:hover {
            outline: none;
            background: fade(@color-primary, 80%);
            color: #fff;
        }

    }

}
