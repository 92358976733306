.Config {


    //display: flex;

    &.is-open & {
        &-box {
            width: 320px;
        }
    }


    &-button {
        padding: 1em;
        position: absolute;
        top: 0;
        right: 0;
        //background: rgba(0,0,0,0.2);
        background: none;
        border: none;

        img {
            width: 30px;
            opacity: 0.5;
        }

        &:hover img {
            opacity: 1;
            cursor: pointer;
        }
    }

    &-box {
        position: absolute;
        top: 4em;
        right: 0;

        width: 0px;

        transition: all .2s ease-in-out;
        overflow: hidden;

        &-inner {

            padding: 2em;

            background: rgba(0,0,0,0.6);
            overflow: hidden;

            color: #fff;

        }


    }


}
