body {
  font-family: arial, verdana, sans-serif;
  font-size: 16px;
  background: #22a3a1;
}
* {
  box-sizing: border-box;
}
.Base {
  background: url('../img/bg.svg') bottom right no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: 80%;
  padding: 3em;
  min-height: 100vh;
}
input {
  border: none;
}
a {
  color: #fff;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
a:hover {
  color: #105240;
}
.Bookmark {
  display: flex;
  width: 100%;
}
.Bookmark-icon {
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.1);
  width: 2em;
  padding: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Bookmark-icon img {
  width: 100%;
}
.Bookmark-name {
  display: block;
  padding: 0.5em 1em;
}
.Bookmark:hover {
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}
.Groups {
  display: flex;
  width: 100%;
  position: relative;
  height: 40vh;
  align-items: center;
}
.Groups-column {
  flex: 3;
  transition: all 0.2s ease-out;
  overflow-x: hidden;
}
.Groups-column-inner {
  transition: all 0.2s ease-in;
  opacity: 0;
  max-height: 40vh;
  overflow-y: scroll;
  margin-right: -17px;
  padding: 1em;
}
.Groups-column.is-open {
  flex-grow: 4;
}
.Groups-column.is-open .Groups-column-inner {
  opacity: 1;
}
.Groups-column.is-open .Groups-bookmarks {
  display: block;
}
.Groups-column.is-open .Groups-title {
  display: none;
}
.Groups-title {
  padding: 0.2em;
  text-align: center;
  color: #fff;
  font-size: 3em;
}
.Groups-icon {
  width: 50%;
  opacity: 0.8;
}
.Groups-bookmarks {
  display: none;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 0.2) 100%);
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 1140px) {
  .Groups-column.is-open {
    flex-grow: 6;
  }
}
@media screen and (max-width: 768px) {
  .Groups {
    flex-wrap: wrap;
    height: auto;
  }
  .Groups-column {
    flex: 1 1 100%;
  }
}
.Comics {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
.Comics-bookmark {
  flex: 1 3 auto;
  padding: 0.5em;
  margin: 0;
}
.Config.is-open .Config-box {
  width: 320px;
}
.Config-button {
  padding: 1em;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
}
.Config-button img {
  width: 30px;
  opacity: 0.5;
}
.Config-button:hover img {
  opacity: 1;
  cursor: pointer;
}
.Config-box {
  position: absolute;
  top: 4em;
  right: 0;
  width: 0px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.Config-box-inner {
  padding: 2em;
  background: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  color: #fff;
}
.Search {
  display: inline-block;
  position: relative;
}
.Search-control {
  display: flex;
}
.Search-input {
  width: 20vw;
  font-size: 2em;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding: 0.5em 1em;
  transition: all ease-in-out 0.2s;
  color: #fff;
  display: block;
  text-shadow: rgba(0, 0, 0, 0.4) 2px 2px 3px;
}
.Search-input:focus {
  width: 60vw;
  outline: none;
  background: rgba(0, 0, 0, 0.1);
}
.Search-input:hover {
  border: 1px solid rgba(255, 255, 255, 0.6);
}
.Search-random {
  display: flex;
  padding: 1em;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  align-items: center;
}
.Search-filter {
  position: absolute;
  width: 100%;
}
.Search-link {
  color: rgba(255, 255, 255, 0.6);
  display: block;
  background: rgba(0, 0, 0, 0.8);
  padding: 0.5em 1em;
}
.Search-link:focus,
.Search-link:hover {
  outline: none;
  background: rgba(32, 168, 130, 0.8);
  color: #fff;
}
.Section {
  padding: 1em;
  text-align: center;
}
.Section--hero {
  padding: 4em;
}
