.Comics {



    display: flex;
    list-style: none;
    flex-wrap: wrap;

    &-bookmark {
        flex: 1 3 auto;
        padding: 0.5em;
        margin: 0;
    }


}
