.Groups {

    @height: 40vh;

    display: flex;
    width: 100%;
    position: relative;
    height: @height;
    align-items: center;

    &:hover & {

    }

    &-column {
        flex: 3;
        transition: all .2s ease-out;
        overflow-x: hidden;

        &-inner {
            transition: all 0.2s ease-in;
            opacity: 0;
            max-height: @height;
            overflow-y: scroll;
            margin-right: -17px;
            padding: 1em;
        }

        &.is-open {
            flex-grow: 4;

            .Groups-column-inner {
                opacity: 1;
            }

            .Groups-bookmarks {
                display: block;
            }
            .Groups-title {
                display:none;
            }
        }
    }

    &-title {
        padding: 0.2em;
        text-align: center;
        color: #fff;
        font-size: 3em;
    }

    &-icon {
        width: 50%;
        opacity: 0.8;
    }

    &-bookmarks {
        display: none;
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;

        background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 10%,rgba(0,0,0,0) 90%,rgba(0,0,0,0.2) 100%);
        @border: 1px solid rgba(255,255,255,0.6);
        border-top: @border;
        border-bottom: @border;
    }

    &-bookmark {
    }

    @media screen and (max-width: 1140px) {
        &-column {
            &.is-open {
                flex-grow: 6;
            }
        }
    }

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        height: auto;
        &-column {
            flex: 1 1 100%;
        }
    }

}
