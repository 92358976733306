body {
    font-family: arial, verdana, sans-serif;
    font-size: 16px;
    background: #22a3a1;
}

* {
    box-sizing: border-box;
}

.Base {

    background: url('../img/bg.svg') bottom right no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-size: 80%;
    padding: 3em;
    min-height: 100vh;
}
