

.Bookmark {

    display: flex;
    //justify-content: center;
    //align-items: center;
    width: 100%;


    &-icon {
        border: 1px solid transparent;
        background: rgba(255,255,255,0.1);
        width: 2em;
        padding: 0.1em;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
        }
    }

    &-name {
        display: block;
        padding: 0.5em 1em;
    }

    &-nsfw {
        //display: block;
    }

    &:hover {
        color: #fff;
        background: rgba(255,255,255,0.2);
    }

}
