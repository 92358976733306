.Section {

    padding: 1em;
    text-align: center;

    &--hero {
        padding: 4em;
    }


}
